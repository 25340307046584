<template>
  <div>

    <el-button class="upload-file" size="small" type="primary" @click="openFileManager">{{ buttonTitle }}</el-button>
    <div v-if="value && value !==''" class="el-upload-list--picture-card">
      <div class="el-upload-list__item" :style="{width:previewWidth,height:previewHeight}" v-if="fileType==='image' || fileType === 'video'">
        <img :key="'image_' + compKey" v-if="fileType==='image'" alt="" :src="getUrl()+value" class="cms-image">
        <video :id="'video_' + compKey" :key="compKey"  v-if="fileType==='video'">
          <source :src="getUrl()+value">
          Your browser does not support the video tag.
        </video>
        <span class="el-upload-list__item-actions">
        <span class="el-upload-list__item-preview" @click="handleMediaCardPreview()"><i
          class="el-icon-zoom-in"></i></span>
        <span class="el-upload-list__item-delete" @click="handleRemove()"><i
          class="el-icon-delete"></i></span></span>
      </div>
      <div v-if="fileType==='document'">
        <a :href="getUrl()+value" target="_blank">
          <i class="fas fa-file-pdf pdf-file-icon p-2"></i>
        </a>
        <i class="fas fa-trash pdf-delete-icon text-danger" @click="handleRemove"></i>
      </div>
      <div v-if="fileType === 'audio'" class="el-upload-list--picture-card">
        <div class="el-upload-list__item audio-display" :style="{width:'100%',height:'auto'}">
          <audio controls style="width:95%">
            <source :src="getUrl()+value">
            Your browser does not support the audio tag.
          </audio>
          <i class="fas fa-trash pdf-delete-icon text-danger" @click="handleRemove"></i>
        </div>
      </div>
    </div>


    <el-dialog :key="'dialog_' +compKey" :visible.sync="dialogVisible">
      <img v-if="fileType==='image'" style="max-width:100%" :src="getUrl()+value" alt="">
      <video v-if="fileType==='video'" id="video-preview" controls width="650" height="300">
        <source :src="getUrl()+value">
        Your browser does not support the video tag.
      </video>
    </el-dialog>
    <file-manager-modal
      :key="'file_manager_' +compKey"
      :visible="fileManagerVisibility"
      :acceptedFiles='acceptedFiles'
      :allowedSelectCount="1"
      @close="closeFileManager()"
      @selectedFiles="setSelectedFiles">
    </file-manager-modal>
  </div>
</template>

<script>

import {Button, Dialog, Upload} from 'element-ui';
import FileManagerModal from '../Modals/FileManagerModal.vue'

export default {

  name: "SingleFileUploaderFileManager",
  props: {
    buttonTitle: {
      default: "Click To Upload"
    },
    previewWidth: {
      type: String,
      default: "240px"
    },
    previewHeight: {
      type: String,
      default: "auto"
    },
    acceptedFiles: {
      type: Array
    },
    fileType: {
      type: String,
      default: "image"
    },
    value: {},
  },

  components: {
    FileManagerModal,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [Button.name]: Button
  },
  data() {
    return {
      disabled: false,
      dialogVisible: false,
      fileManagerVisibility: false,
      compKey:0,
    };
  },
  mounted() {
  },
  methods: {
    handleRemove() {
      this.$emit('input', "");
    },
    handleMediaCardPreview() {
      this.dialogVisible = true;
    },
    openFileManager() {
      this.fileManagerVisibility = true;
    },
    closeFileManager() {
      this.fileManagerVisibility = false;
    },
    setSelectedFiles(files) {
      if (files && files.length) {
        this.compKey++;
        this.$emit('input', files[0]);
      }
      this.fileManagerVisibility = false;
    },
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },

  }

}
</script>
<style scoped>
.el-upload-list__item {
  margin-top: 10px;
}

.upload-file {
  display: block;
}
.cms-image{
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.pdf-file-icon{
  font-size: 100px;
}
.pdf-delete-icon{
  cursor: pointer;
}
.audio-display{
  display: flex;
  align-items: center;
}
</style>
