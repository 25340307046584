<template>

  <div class="row" ref="newsForm">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <div class="col-12">
          <div class="card card-wizard" id="wizardCard">
            <form-wizard shape="tab"
                         :title="formTitle"
                         :subtitle="''"
                         error-color="#FB404B"
                         color="#35495E"
                         ref="panelFormWizard">
              <tab-content title="English Content"
                           class="col-12"
                           icon="nc-icon nc-album-2"
                           :before-change="() => validateStep('englishStep')">
                <english-step :formData="formData ? formData['en'] : defaultFormData['en']"
                              :editMode="editMode"
                              ref="englishStep"
                              :builderData="builderData"
                              @on-validated="onStepValidated" @submit-step="submitStep"></english-step>
              </tab-content>
              <tab-content v-for="(lang, index) in languageList" :key="index" :title="lang.label+' Content'"
                           class="col-12"
                           icon="nc-icon nc-album-2"
                           :before-change="() => validateStep(lang.value)">
                <other-languages-step
                  :formData="formData ? formData[lang.value] : defaultFormData[lang.value]"
                  :editMode="editMode"
                  :isSubmit="languageList.length - 1 === index"
                  :lang="lang" :ref="lang.value" @on-validated="onStepValidated"
                  @submit-step="submitStep"></other-languages-step>
              </tab-content>

              <button slot="prev" class="btn btn-default btn-fill btn-wd btn-back">Back</button>
              <button slot="next" class="btn btn-default btn-fill btn-wd btn-next">Next</button>
              <button slot="finish" class="btn btn-success btn-fill btn-wd" :disabled="entityNotFoundError || submitting">Finish</button>
            </form-wizard>
          </div>
        </div>
      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import EnglishStep from "./Wizard/EnglishStep";
import OtherLanguagesStep from "./Wizard/OtherLanguagesStep";
import {mapGetters} from "vuex";



extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormWizard,
    TabContent,
    OtherLanguagesStep,
    EnglishStep,
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      imagesExtensions: this.getImagesExtensions(),
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {},
      defaultFormData: {
        title: "",
        image: "",
        is_active: true,
        is_featured: false,
        date: null,
        is_updated: false,
      },
      wizardModel: {},
      builderData: {
        series: [],
        VideoTypes: [],
      },

    };
  },
  computed: {
    ...mapGetters({
      languageList: "global/languageList"
    })
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.newsForm
    });
    this.setNullFormData();
    this.id = this.$route.params['id'];
    let data = {id: this.id}
    this.axios.post("series/builder", data).then((response) => { //this should be edited after BE update
      this.builderData.seriesType = response.data.seriesTypes;
      this.builderData.series = response.data.series;
      this.builderData.VideoTypes = response.data.videoTypes;

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Series";
        this.getSeries();
      } else {
        this.editMode = false;
        this.formTitle = "Add Series";
        this.loader.hide();
      }
    }).catch((error) => {
      console.error(error);
    })

  },
  methods: {
    getSeries() {
      let data = {
        'id': this.id,
      };
      this.axios.post("series/get", data).then((response) => { //this should be edited after BE update
        this.formData = response.data;
        this.formData['en'].related_series = response.data['en'].relatedSeriesBack.map(series => series.id);
        this.vueDate();
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Series Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      this.laravelDate();
      if (this.formData['en'].video_type === "UPLOADED") {
        this.formData['en'].embedded_video = '';
      } else {
        this.formData['en'].uploaded_video = '';
      }
      if (this.editMode === true) {
        request = this.axios.put("series/update/" + this.id, this.formData);
        successMessage = "Series Updated Successfully";
      } else {
        request = this.axios.post("series/create", this.formData);
        successMessage = "Series Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/series/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
          this.$refs.panelFormWizard.navigateToTab(0);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    vueDate(){
      this.formData.date = (this.formData.date)? this.formData.date.replace(' ', 'T'):this.formData.date;
    },
    laravelDate(){
      this.formData.date = (this.formData.date)? this.formData.date.replace('T', ' '):this.formData.date;
    },
    validateStep(ref) {
      if (Array.isArray(this.$refs[ref])){
        return this.$refs[ref][0].validate();
      }else{
        return this.$refs[ref].validate();
      }
    },
    onStepValidated(validated, model) {
      this.wizardModel = {...this.wizardModel, ...model}
    },
    submitStep(data, lang, isSubmit){
      this.formData[lang] = data;
      if (isSubmit){
        this.submit();
      }
    },
    setNullFormData(){
      this.defaultFormData['en'] = {
        name: '',
        ident: '',
      };
      this.languageList.forEach(lang => {
        this.defaultFormData[lang] = {
          name: '',
          ident: '',
        }
      })
    },
  }
}
</script>
