import DashboardLayout from "@/pages/layouts/DashboardLayout";

let newsRoutes = {
  path: '/news',
  component: DashboardLayout,
  redirect: '/news/list',
  children: [
    {
      path: 'list',
      name: 'Press List',
      component: () => import(/* webpackChunkName: "news" */  '@/pages/news/NewsList'),
      meta: {
        permissionsCodes: ['news/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Press',
      component: () => import(/* webpackChunkName: "news" */  '@/pages/news/NewsForm'),
      meta: {
        permissionsCodes: ['news/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Press',
      component: () => import(/* webpackChunkName: "news" */  '@/pages/news/NewsForm'),
      meta: {
        permissionsCodes: ['news/update'],
      }
    }
  ]
}

export default newsRoutes;
