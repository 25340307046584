import DashboardLayout from "@/pages/layouts/DashboardLayout";

let bolgRoutes = {
  path: '/blogs',
  component: DashboardLayout,
  redirect: '/blogs/list',
  children: [
    {
      path: 'list',
      name: 'News List',
      component: () => import(/* webpackChunkName: "blogs" */  '@/pages/blogs/BlogList'),
      meta: {
        permissionsCodes: ['blogs/index'],
      }
    },
    {
      path: 'create',
      name: 'Add News',
      component: () => import(/* webpackChunkName: "blogs" */  '@/pages/blogs/BlogForm'),
      meta: {
        permissionsCodes: ['blogs/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit News',
      component: () => import(/* webpackChunkName: "blogs" */  '@/pages/blogs/BlogForm'),
      meta: {
        permissionsCodes: ['blogs/update'],
      }
    }
  ]
}

export default bolgRoutes;
