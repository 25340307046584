import axios from 'axios';

export default {
  namespaced: true,

  state: {
    mediaInfo: null,
    languageList: [],
  },

  getters: {
    mediaInfo(state) {
      return state.mediaInfo;
    },
    languageList(state){
      return state.languageList
    },
  },

  mutations: {
    SET_MEDIA_INFO(state, mediaInfo) {
      state.mediaInfo = mediaInfo;
    },
    SET_LANGUAGE_LIST(state, languageList) {
      state.languageList = languageList;
    },
  },

  actions: {

    async index({commit, state}) {
      try {
        let res = await axios.get("global/index");
        commit('SET_MEDIA_INFO', res.data.mediaInfo);
        commit('SET_LANGUAGE_LIST', res.data.languageList);
      } catch (e) {
        commit('SET_MEDIA_INFO', null);
        commit('SET_LANGUAGE_LIST', []);
      }
    }

  }
}
