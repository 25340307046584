import DashboardLayout from "@/pages/layouts/DashboardLayout";

let focusAreaRoutes = {
  path: '/focus-areas',
  component: DashboardLayout,
  redirect: '/focus-areas/list',
  children: [
    {
      path: 'list',
      name: 'Focus Area List',
      component: () => import(/* webpackChunkName: "focus-areas" */  '@/pages/focusAreas/FocusAreaList'),
      meta: {
        permissionsCodes: ['focus-areas/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Focus Area',
      component: () => import(/* webpackChunkName: "focus-areas" */  '@/pages/focusAreas/FocusAreaForm'),
      meta: {
        permissionsCodes: ['focus-areas/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Focus Area',
      component: () => import(/* webpackChunkName: "focus-areas" */  '@/pages/focusAreas/FocusAreaForm'),
      meta: {
        permissionsCodes: ['focus-areas/update'],
      }
    }
  ]
}

export default focusAreaRoutes;
