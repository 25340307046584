import DashboardLayout from "@/pages/layouts/DashboardLayout";

let eventRoutes = {
  path: '/events',
  component: DashboardLayout,
  redirect: '/events/list',
  children: [
    {
      path: 'list',
      name: 'Event List',
      component: () => import(/* webpackChunkName: "events" */  '@/pages/events/EventList'),
      meta: {
        permissionsCodes: ['events/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Event',
      component: () => import(/* webpackChunkName: "events" */  '@/pages/events/EventForm'),
      meta: {
        permissionsCodes: ['events/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Event',
      component: () => import(/* webpackChunkName: "events" */  '@/pages/events/EventForm'),
      meta: {
        permissionsCodes: ['events/update'],
      }
    }
  ]
}

export default eventRoutes;
