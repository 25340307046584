<template>
  <div class="form-group">
    <label v-if="label">
      {{ label }}
    </label>

    <el-select
      v-on="$listeners"
      v-bind="$attrs"
      :class="[inputClasses]"
      @change="updated"
      v-model="form">
      <el-option v-for="option in list"
                 :class="[inputClasses]"
                 :value="option[listItemValue]"
                 :label="option[listItemLabel]"
                 :key="option[listItemValue]">
      </el-option>
    </el-select>

    <div class="text-danger invalid-feedback" style="display: block;" v-if="error">
      {{ error }}
    </div>

  </div>
</template>
<script>
import {Option, Select} from "element-ui";

export default {
  name: 'fg-select',
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
  },
  props: {

    label: {
      type: String,
    },

    inputClasses: {
      type: String,
    },

    error: {
      type: String
    },

    value: {
      required: true
    },

    list: {
      type: Array,
      default: null
    },

    listItemLabel: {
      type: String,
    },

    listItemValue: {
      type: String,
    },

  },

  watch: {

    error(value) {},

    value(value) {
      this.form = value;
    }
  },

  data() {
    return {
      form: this.value
    }
  },

  methods: {
    updated(value) {
      this.form = value;
      this.$emit('input', this.form);
    }
  }

}
</script>

<style scoped>
.el-select-dropdown__item {
  white-space: pre !important;
}
</style>
