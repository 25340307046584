import DashboardLayout from '@/pages/layouts/DashboardLayout.vue'
import NotFound from '@/pages/NotFoundPage.vue'
import Overview from '@/pages/Overview.vue'
import Login from '@/pages/authPages/Login.vue';
import rolesRoutes from "@/routes/rolesRoutes";
import permissionsRoutes from "@/routes/permissionsRoutes";
import usersRoutes from "@/routes/usersRoutes";
import branchesRoutes from "@/routes/pages/branchesRoutes";
import pagesRoutes from "@/routes/pages/pagesRoutes";
import redirectionRoutes from "@/routes/pages/redirectionPagesRoutes";
import contentRoutes from "@/routes/pages/contentPagesRoutes";
import panelGroupsRoutes from "@/routes/panels/panelGroupsRoutes";
import panelRoutes from "@/routes/panels/panelRoutes";
import settingsRoutes from "@/routes/general/settingsRoutes";
import UpdatePassword from "@/pages/profile/UpdatePassword";
import UpdateProfile from "@/pages/profile/UpdateProfile";
import ForgetPassword from "@/pages/authPages/ForgetPassword";
import ResetPassword from "@/pages/authPages/ResetPassword";
import newsletterRoutes from "@/routes/general/newsletterRoutes";
import contactusRoutes from "./general/contactusRoutes";
import mediaInfoRoutes from "@/routes/general/mediaInfoRoutes";
import newsRoutes from "./news/newsRoutes";
import initiativeRoutes from "./initiatives/initiativeRoutes";
import partnerRoutes from "./partners/partnerRoutes";
import faqRoutes from "./faq/faqRoutes";
import focusAreaRoutes from "./focusAreas/focusAreaRoutes";
import bolgRoutes from "./blogs/bolgRoutes";
import newsTypeRoutes from "./news/newsTypeRoutes";
import eventRoutes from "./events/eventRoutes";
import SeriesForm from '@/pages/series/SeriesForm.vue';
import SeriesList from '@/pages/series/SeriesList.vue';

let dashboardRoutes = {
  path: '/',
  component: DashboardLayout,
  redirect: '/overview',
  children: [
    {
      path: 'overview',
      name: 'Overview',
      component: Overview,
    },
    {
      path: 'change-my-password',
      name: 'Update Password',
      component: UpdatePassword,
    },
    {
      path: 'update-profile',
      name: 'Update Profile',
      component: UpdateProfile,
    },
  ],
};

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
};
let forgetPasswordPage = {
  path: '/forget-password',
  name: 'Forget Password',
  component: ForgetPassword
};

let resetPassword = {
  path: '/reset-password/:token',
  name: 'Reset Password',
  component: ResetPassword
};

let seriesRoutes = {
  path: '/series',
  component: DashboardLayout,
  children: [
    {
      path: '',
      name: 'Series List',
      component: SeriesList,
    },
    {
      path: 'create',
      name: 'Create Series',
      component: SeriesForm,
    },
    {
      path: 'edit/:id',
      name: 'Edit Series',
      component: SeriesForm,
    },
  ],
};

const routes = [
  {
    path: '/',
    redirect: '/overview'
  },
  loginPage,
  forgetPasswordPage,
  resetPassword,
  rolesRoutes,
  permissionsRoutes,
  redirectionRoutes,
  usersRoutes,
  branchesRoutes,
  contentRoutes,
  pagesRoutes,
  panelGroupsRoutes,
  panelRoutes,
  settingsRoutes,
  newsletterRoutes,
  contactusRoutes,
  dashboardRoutes,
  mediaInfoRoutes,
  newsRoutes,
  initiativeRoutes,
  partnerRoutes,
  faqRoutes,
  focusAreaRoutes,
  bolgRoutes,
  newsTypeRoutes,
  eventRoutes,
  seriesRoutes,
  { path: '*', name: 'Not Found', component: NotFound }
]

export default routes