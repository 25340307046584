import DashboardLayout from "@/pages/layouts/DashboardLayout";

let initiativeRoutes = {
  path: '/initiatives',
  component: DashboardLayout,
  redirect: '/initiatives/list',
  children: [
    {
      path: 'list',
      name: 'Initiative List',
      component: () => import(/* webpackChunkName: "initiatives" */  '@/pages/initiatives/InitiativeList'),
      meta: {
        permissionsCodes: ['initiatives/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Initiative',
      component: () => import(/* webpackChunkName: "initiatives" */  '@/pages/initiatives/InitiativeForm'),
      meta: {
        permissionsCodes: ['initiatives/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Initiative',
      component: () => import(/* webpackChunkName: "initiatives" */  '@/pages/initiatives/InitiativeForm'),
      meta: {
        permissionsCodes: ['initiatives/update'],
      }
    }
  ]
}

export default initiativeRoutes;
