<template>
  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>

    <side-bar>
      <user-menu></user-menu>
      <mobile-menu></mobile-menu>

      <template slot-scope="props" slot="links">
        <sidebar-item :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['events/index'])"
                      :link="{name: 'Events', icon: 'nc-icon nc-watch-time', path: '/events'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['initiatives/index'])"
                      :link="{name: 'Initiatives', icon: 'nc-icon nc-app', path: '/initiatives'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['news-types/index','news/index'])"
                      :link="{name: 'News', icon: 'nc-icon nc-paper-2'}">
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['news/index'])"
            :link="{name: 'News', path: '/news/list'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['news-types/index'])"
            :link="{name: 'News Types', path: '/news-types/list'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['partners/index'])"
                      :link="{name: 'Partners', icon: 'nc-icon nc-puzzle-10', path: '/partners'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['focus-areas/index'])"
                      :link="{name: 'Focus Areas', icon: 'nc-icon nc-compass-05', path: '/focus-areas/list'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['FAQ/index'])"
                      :link="{name: 'FAQ', icon: 'nc-icon nc-caps-small', path: '/FAQ/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['contact-us/index'])"
                      :link="{name: 'Contact Messages', icon: 'nc-icon nc-support-17', path: '/contact-us'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['series/index'])"
                      :link="{name: 'Series',  icon: 'nc-icon nc-single-copy-04', path: '/series'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['pages/index',
                                             'content-pages/create',
                                             'redirection-pages/create',
                                             'branches/index'])"
                      :link="{name: 'Pages', icon: 'nc-icon nc-single-copy-04'}">
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['pages/index'])"
            :link="{name: 'Existing Pages', path: '/pages/list'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['content-pages/create'])"
                        :link="{name: 'Add Content Page', path: '/content-pages/create'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['redirection-pages/create'])"
                        :link="{name: 'Add Redirection Page', path: '/redirection-pages/create'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['branches/index'])"
            :link="{name: 'Branches', path: '/branches/list'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['panel-groups/index','panels/index'])"
                      :link="{name: 'Panels And Banners', icon: 'nc-icon nc-album-2'}">
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['panels/index'])"
            :link="{name: 'Panels', path: '/panels/list'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['panel-groups/index'])"
            :link="{name: 'Groups', path: '/panel-groups/list'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['settings/index'])"
                      :link="{name: 'Settings', icon: 'nc-icon nc-settings-gear-64', path: '/settings/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')
                            || $store.getters['auth/checkAccess']('users/index')
                            || $store.getters['auth/checkAccess']('users/deleted-users')"
                      :link="{name: 'Access Management', icon: 'nc-icon nc-circle-09'}">
          <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')"
                        :link="{name: 'Roles', path: '/roles/list'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/index')"
                        :link="{name: 'Users', path: '/users/list'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/deleted-users')"
                        :link="{name: 'Deleted Users', path: '/users/DeletedUsersList'}"></sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar"></dashboard-content>
      <content-footer></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import MobileMenu from './Extra/MobileMenu.vue'
import UserMenu from './Extra/UserMenu.vue'

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    }
  }
}
</script>