<template>
  <div>

    <el-button class="upload-file" size="small" type="primary" @click="openFileManager">{{ buttonTitle }}</el-button>

    <draggable v-model="value" group="gallery" @start="drag=true" @end="moveEventfunction" class="d-flex flex-wrap">
      <div v-for="(file, index) in this.value" class="el-upload-list--picture-card">
        <div v-if="fileType === 'document'" class="document-icon">
          <a :href="getUrl()+file.image" target="_blank">
            <i class="fas fa-file-pdf pdf-file-icon p-2"></i>
          </a>
          <i class="fas fa-trash pdf-delete-icon text-danger" @click="handleRemove(x)"></i>
        </div>
        <div v-else class="el-upload-list__item" :style="{width:previewWidth,height:previewHeight}">
          <img alt="" :src="getUrl() + file.image" class="cms-image">
          <span class="el-upload-list__item-actions">
        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(index)"><i
          class="el-icon-zoom-in"></i></span>
        <span class="el-upload-list__item-delete" @click="handleRemove(index)"><i
          class="el-icon-delete"></i></span></span>
        </div>
      </div>
    </draggable>


    <el-dialog :visible.sync="dialogVisible">
      <img style="max-width:100%" :src="getUrl() +dialogImageUrl" alt="">
    </el-dialog>
    <file-manager-modal :visible="fileManagerVisibility"
                        :acceptedFiles='acceptedFiles'
                        :allowedSelectCount="numberChosenFiles"
                        @close="closeFileManager()"
                        @selectedFiles="setSelectedFiles">
    </file-manager-modal>
  </div>
</template>

<script>

import {Button, Dialog, Upload} from 'element-ui';
import FileManagerModal from '../Modals/FileManagerModal.vue'
import draggable from 'vuedraggable';

export default {

  name: "MultipleFileUploaderFileManager",
  props: {
    buttonTitle: {
      default: "Click To Upload"
    },
    filesNumber: {
      type: Number,
      default: 2
    },
    previewWidth: {
      type: String,
      default: "240px"
    },
    previewHeight: {
      type: String,
      default: "auto"
    },
    fileType: {
      type: String,
      default: "image"
    },
    acceptedFiles: {
      type: Array
    },
    value: {},
  },

  components: {
    FileManagerModal,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    draggable,
  },
  data() {
    return {
      dialogImageUrl: '',
      disabled: false,
      dialogVisible: false,
      fileManagerVisibility: false,
      numberChosenFiles: 0,
    };
  },
  mounted() {
    this.numberChosenFiles = this.filesNumber - this.value.length;
  },
  methods: {
    moveEventfunction() {
      let value = this.value.slice()
      value.map((element, index) => {
        element.sort_order = index
      });
      this.$emit("input", value);
    },
    handleRemove(index) {
      let value = this.value.slice()
      value.splice(index, 1);
      this.numberChosenFiles = this.filesNumber - value.length;
      this.$emit("input", value);
    },
    handlePictureCardPreview(index) {
      this.dialogImageUrl = this.value[index].image;
      this.dialogVisible = true;
    },
    openFileManager() {
      this.fileManagerVisibility = true;
    },
    closeFileManager() {
      this.fileManagerVisibility = false;
    },
    setSelectedFiles(files) {
      let addedFile;
      if (this.fileType === 'document'){
        addedFile = files.map((file, index) => {
          return {id: null, file: file, sort_order: index}
        });
      }else{
        addedFile = files.map((file, index) => {
          return {id: null, image: file, sort_order: index}
        });
      }
      let value = this.value ? this.value.slice() : [];
      value.push(...addedFile);
      this.numberChosenFiles = this.filesNumber - value.length;
      this.$emit("input", value);

      this.fileManagerVisibility = false;
    },
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL ;
    },
  }

}
</script>
<style scoped>
.el-upload-list__item {
  margin-top: 10px;
}

.upload-file {
  display: block;
}

.cms-image{
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.document-icon{
  margin-right: 10px;
}
.document-icon a{
  font-size: 100px;
}
</style>
